/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function SEO({ title: pageTitle, meta }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query SEOQuery {
        site {
          siteMetadata {
            title
            description
            author
            url
            image
          }
        }
      }
    `
  )

  const siteUrl = site.siteMetadata.url
  const { featured_media, article_published_time } = meta

  const defaultImageUrl =
    featured_media?.localFile?.childImageSharp?.fluid?.src ||
    site.siteMetadata.image
  const ogImage = meta.og_image && meta.og_image[0]
  const ogImageUrl =
    ogImage?.url?.localFile?.childImageSharp?.fixed?.src || defaultImageUrl
  const ogImageMeta = {
    alt_text: ogImage?.url?.alt_text,
    width: ogImage?.width,
    height: ogImage?.height,
  }
  const twitterImageUrl =
    meta.twitter_image?.localFile?.childImageSharp?.fluid?.src ||
    defaultImageUrl

  const ogUrl = siteUrl + pathname
  const title = meta.title || `${pageTitle} | ${site.siteMetadata.title}`
  const ogTitle = meta.og_title || title
  const twitterTitle = meta.twitter_title || title
  const defaultDescription = meta.description || site.siteMetadata.description
  const ogDescription = meta.og_description || defaultDescription
  const twitterDescription = meta.twitter_description || defaultDescription

  return (
    <Helmet
      htmlAttributes={{
        lang: meta.og_locale || "nl",
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: defaultDescription,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:url`,
          content: ogUrl,
        },
        {
          property: `og:type`,
          content: meta.og_type || `website`,
        },
        {
          name: `article:published_time`,
          content: article_published_time,
        },
        {
          name: `article:modified_time`,
          content: meta.article_modified_time,
        },
        {
          name: `twitter:title`,
          content: twitterTitle,
        },
        {
          name: `twitter:description`,
          content: twitterDescription,
        },
        {
          name: `twitter:card`,
          content: meta.twitter_card || `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        // search console verification
        {
          name: "google-site-verification",
          content: "jws7GQxnwjzRpZ75YM4Go74OHOvhRNBlX4Kw7OLnf-w",
        },
      ].concat(
        defaultImageUrl || ogImageUrl || twitterImageUrl
          ? [
              {
                property: `og:image`,
                content: siteUrl + ogImageUrl,
              },
              {
                property: `og:image:alt`,
                content: ogImageMeta.alt_text,
              },
              {
                property: "og:image:width",
                content: ogImageMeta.width,
              },
              {
                property: "og:image:height",
                content: ogImageMeta.height,
              },
              {
                name: `twitter:image`,
                content: siteUrl + twitterImageUrl,
              },
            ]
          : []
      )}
    />
  )
}

SEO.defaultProps = {
  meta: {},
}

SEO.propTypes = {
  meta: PropTypes.shape({
    og_locale: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    og_title: PropTypes.string,
    twitter_title: PropTypes.string,
    og_description: PropTypes.string,
    twitter_description: PropTypes.string,
    og_type: PropTypes.string,
    twitter_card: PropTypes.string,
    og_image: PropTypes.arrayOf(
      PropTypes.shape({
        alt_text: PropTypes.string,
        localFile: PropTypes.object,
      })
    ),
    twitter_image: PropTypes.shape({
      alt_text: PropTypes.string,
      localFile: PropTypes.object,
    }),
    article_modified_time: PropTypes.string,
    article_published_time: PropTypes.string,
    featured_media: PropTypes.shape({
      alt_text: PropTypes.string,
      localFile: PropTypes.object,
    }),
  }),
  title: PropTypes.string,
}

export default SEO
