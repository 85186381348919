import React from "react"
import { Link } from "gatsby"
import Image from "safe-gatsby-image-plugin"

import Row from "./row"
import Heading from "./heading"
import Contact_Section from "./contact_section"

import s from "./startscreen.module.scss"
import Section from "./section"

const StartScreen = ({ siteData, roles }) => {
  const { title, homePageData } = siteData
  // this state entry determines whether to run the fade-out animation (happens after a user has selected their content)

  const renderCards = (data, index) => {
    const { icon, title, text } = data
    return (
      <>
        <Image
          style={{
            height: "100%",
            width: "100%",
          }}
          data={icon}
          imgStyle={index < 2 ? { maxHeight: 280 } : null}
        />
        <div className={s.content}>
          <div className={s.content_inner}>
            <h3 className={s.title}>{title}</h3>
            <div className={s.slide_box}>
              <p>{text}</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={s.wrapper}>
      <div className={[s.inner].join(" ")}>
        <Row customClass={s.startscreen_hero}>
          <Heading type="h1" color={"white"}>
            {title}
          </Heading>
          <div className={s.roles}>
            <div className={s.left}>
              {roles.map(({ node }, i) => {
                const { ...data } = node.acf.roles
                // only render first two items in this column
                if (i >= 2) return null
                return (
                  <Link key={i} to={node.slug} className={[s.role].join(" ")}>
                    {renderCards(data, i)}
                  </Link>
                )
              })}
            </div>
            {roles.map(({ node }, i) => {
              const { ...data } = node.acf.roles
              if (i < 2) return null
              return (
                <Link
                  key={i}
                  to={node.slug}
                  className={[s.role, s.right].join(" ")}
                >
                  {renderCards(data, i)}
                </Link>
              )
            })}
          </div>
        </Row>
      </div>
      {homePageData.sections.map((section, idx) => (
        <Section
          key={`homepageSection_${idx}`}
          data={{ ...section, title: section.heading }}
          style={idx % 2 === 0 ? "light" : "dark"}
        />
      ))}
      <Contact_Section data={homePageData.contact} />
    </div>
  )
}

export default StartScreen
