import React from "react"
import l from "./../layout/layout.module.scss"

export const formatBtnLabel = label => {
  let wordsArr = label.split(" ")
  return wordsArr.map((word, i) => (
    <span key={i}>
      <span className={i % 2 === 0 ? l.first_char : l.last_char}>
        {word.charAt(0)}
      </span>
      {word.substring(1)}
      {wordsArr.length - 1 !== i ? " " : ""}
    </span>
  ))
}
