import React from "react"
import Image from "safe-gatsby-image-plugin"
import Button from "./button"
import Heading from "./heading"
import Row from "./row"

import l from "./../components/layout/layout.module.scss"
import a from "./../templates/about.module.scss"

const Section = ({ data, style = "dark" }) => {
  const { title, text, button, image } = data
  return (
    <Row id={title} customClass={a.info} bgColor={style}>
      <div
        className={[a.content, style === "light" ? a.dark : a.light].join(" ")}
      >
        <div className={l.grid_2}>
          <div>
            <Heading>{title}</Heading>
            <div
              className={l.text_under_heading}
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
            <Button
              color={style === "light" ? "dark" : "light"}
              data={button}
            />
          </div>
          <div className={a.right}>
            <div>
              <Image data={image} />
            </div>
          </div>
        </div>
      </div>
    </Row>
  )
}

export default Section
