import React, { useState, useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import f from "./form.module.scss"
import l from "./../components/layout/layout.module.scss"

import { formatBtnLabel } from "./utils/helpers"

const Form = ({
  fields,
  btnLabel,
  fileUpload = false,
  accent = "dark",
  dropDownOptions = [],
}) => {
  const [formEntries, saveFormEntries] = useState({})
  const [files, setFiles] = useState(null)
  const [formSent, setFormSent] = useState(false)
  const [formIsProcessing, setFormIsProcessing] = useState(false)
  const [dropDownActiveItemIndex, setDropDownActiveItemIndex] = useState(0)
  const [dropDownActive, setDropDownActive] = useState(false)

  const dropDown = dropDownOptions.length > 0

  const formRef = useRef()

  const { url } = useStaticQuery(
    graphql`
      query {
        wordpressSiteMetadata {
          url
        }
      }
    `
  ).wordpressSiteMetadata

  const api = `${url}/mailer/index.php`
  // const api = "http://localhost:5000/index.php"

  const handleFormEntry = input => {
    let name, value
    if (input.target) {
      ;({ name, value } = input.target)
    } else ({ name, value } = input)
    saveFormEntries({
      ...formEntries,
      [name]: value,
    })
  }

  const handleFileEntry = e => {
    setFiles(e.target.files)
  }

  const toCamelCase = str => {
    return str
      .split(" ")
      .map((word, i) =>
        i > 0
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word.toLowerCase()
      )
      .join("")
  }

  const handleSelect = (evt, optionIndex) => {
    if (!dropDownActive || !optionIndex) return
    evt.stopPropagation()
    handleFormEntry({
      name: toCamelCase(dropDownOptions[0].replace(":", "")),
      value: dropDownOptions[optionIndex],
    })
    setDropDownActiveItemIndex(optionIndex)
    setDropDownActive(!dropDownActive)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const formData = new FormData()

    // Add form entries
    const sanitize = str => {
      // this regex selects all characters except [a-z] and [0-9] and _
      let regex0 = RegExp(/[^a-z|_|0-9]/, "gi")
      return str.replace(" ", "_").replace(regex0, "")
    }

    for (const [key, value] of Object.entries(formEntries)) {
      formData.append(sanitize(key), value)
    }

    // Add files if present
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i])
      }
    }

    setFormIsProcessing(true)
    fetch(api, {
      method: "POST",
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        //Handle your data
        if (data === "success") {
          // reset state container entries and form itself
          formRef.current.reset()
          saveFormEntries({})
          setDropDownActiveItemIndex(0)

          setFormIsProcessing(false)
          setFormSent(true)

          // remove success message after 5 seconds
          let timeout = setTimeout(() => {
            setFormSent(false)
            clearTimeout(timeout)
          }, 5000)
        }
      })
  }

  return (
    <form
      ref={formRef}
      onSubmit={e => handleSubmit(e)}
      className={[f.form, f[accent]].join(" ")}
    >
      {fields.map(({ type, label, width, required }, index) => (
        <div style={{ width }} key={index} className={f.form_group}>
          {type === "textarea" ? (
            <textarea
              rows="3"
              name={label.toLowerCase()}
              id={label.toLowerCase()}
              onChange={e => handleFormEntry(e)}
              required={required}
              className={formEntries[label.toLowerCase()] && f.active}
            ></textarea>
          ) : (
            <input
              type={type}
              name={label.toLowerCase()}
              id={label.toLowerCase()}
              onChange={e => handleFormEntry(e)}
              required={required}
              className={formEntries[label.toLowerCase()] && f.active}
            />
          )}
          <label htmlFor={label.toLowerCase()}>{label}</label>
        </div>
      ))}
      {dropDown && (
        <div
          onClick={() => setDropDownActive(!dropDownActive)}
          className={[f.formGroup, f.dropDown, dropDownActive && f.active].join(
            " "
          )}
        >
          <ul className={f.select}>
            {dropDownOptions.map((option, i) => (
              <li
                onClick={e => handleSelect(e, i)}
                className={[
                  f.option,
                  i === dropDownActiveItemIndex && f.active,
                ].join(" ")}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
      {fileUpload && (
        <div style={{ width: "100%" }} className={f.form_group}>
          <input
            onChange={e => handleFileEntry(e)}
            type="file"
            name="files[]"
            id="files"
            multiple="multiple"
          />
        </div>
      )}
      <div className={[f.message, formSent && f.success].join(" ")}>
        Uw aanvraag werd successvol verstuurd!
      </div>
      <button className={[l.btn, l[accent]].join(" ")}>
        {formatBtnLabel(
          formIsProcessing ? "Verwerken..." : btnLabel || "Verstuur"
        )}
      </button>
      <div className={f.consent}>
        <p>
          Met het versturen van dit formulier gaat u akkoord met onze{" "}
          <Link to="/privacyverklaring">privacyverklaring</Link>
        </p>
      </div>
    </form>
  )
}

export default Form
