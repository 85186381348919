import React from "react"

import Row from "./row"
import Heading from "./heading"
import Form from "./form"
import Image from "safe-gatsby-image-plugin"

import l from "./layout/layout.module.scss"
import c from "./contact_section.module.scss"

const Contact_Section = ({ data }) => {
  return (
    <Row id="contact" bgColor="white">
      <div className={[l.grid_2, c.form_row].join(" ")}>
        <div>
          <Heading>{data.contactinfo_title}</Heading>
          <div className={l.grid_2}>
            <div>
              <h4>Adres</h4>
              <ul className={c.contact_entries}>
                {data.address.split(",").map(i => (
                  <li key={i}>{i}</li>
                ))}
              </ul>
            </div>
            <div>
              <h4>Telefoon/Email</h4>
              <ul className={c.contact_entries}>
                {data.phone.split(",").map((phoneStr, i) => {
                  // Split tel string in name and number -> assume name:number format
                  const arr = phoneStr.split(":")
                  // logic below is when there is no name
                  return (
                    <li key={"phone_" + i}>
                      {arr.length > 1 && <span>{arr[0]}: </span>}
                      <a href={`tel:${arr[arr.length - 1]}`}>
                        {arr[arr.length - 1]}
                      </a>
                    </li>
                  )
                })}
                <li className={c.email}>
                  <a href={`mailto:${data.email}`}>{data.email}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className={c.side_img}>
            <Image data={data.form_image} />
          </div>
        </div>
        <div>
          <Heading>{data.title}</Heading>
          <Form btnLabel={data.btn_label} fields={data.wordpress_fields} />
        </div>
      </div>
    </Row>
  )
}

export default Contact_Section
