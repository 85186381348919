import React from "react"

import Row from "../row"
import Image from "safe-gatsby-image-plugin"

import f from "./footer.module.scss"

const Footer = ({ social_icons = [] }) => {
  return (
    <footer>
      <Row customClass={f.footer} bgColor="anthracite">
        <div className={f.content}>
          <p className={f.copyright}>
            Copyright © {new Date().getFullYear()} Duriso
          </p>
          <div className={f.social}>
            {social_icons.map(i => (
              <a key={i.icoon.id} href={i.btn_link}>
                <Image data={i.icoon} />
              </a>
            ))}
          </div>
        </div>
      </Row>
    </footer>
  )
}

export default Footer
