import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Row from "../row"
import Image from "safe-gatsby-image-plugin"

import h from "./header.module.scss"
import s from "./../startscreen.module.scss"

const Header = ({ siteData, roles, menuItems, mobileMenuItems }) => {
  const [menu, toggleMenu] = useState(false)

  const renderCard = (img, title, slug) => {
    return (
      <Link activeClassName={h.active} key={slug} to={`/${slug}`}>
        <div className={h.nav_card}>
          <Image className={h.img} data={img} />
          <div className={h.content}>{title}</div>
        </div>
      </Link>
    )
  }

  useEffect(() => {
    if (menu) {
      // Make body sticky when fullscreen menu is open
      document.body.style.position = "fixed"
    } else {
      document.body.style.position = "unset"
    }
  }, [menu])

  const renderMenuItems = items =>
    items.map(item => (
      <li key={item.url} className={h.nav_item}>
        <Link activeClassName={h.active} to={item.url}>
          {item.title}
          <div className={s.dot_pulse}></div>
        </Link>
        {item.child_items && (
          <ul className={h.nav_inner_items}>
            {renderMenuItems(item.child_items)}
          </ul>
        )}
      </li>
    ))

  const {
    contact_img: contactImg,
    logos: { logo_gw: logo },
  } = siteData

  return (
    <header className={[h.header, menu && h.active].join(" ")}>
      <Row customClass={h.header_wrapper}>
        <div className={h.grid}>
          <button
            onClick={() => toggleMenu(!menu)}
            className={h.menu_btn_container}
          >
            <div className={h.menu_btn}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={h.menu_btn_label}>Menu</div>
          </button>
          <Link to="/">
            <Image className={h.logo} data={logo} />
          </Link>
        </div>
      </Row>
      {menu && (
        <Row>
          <div className={h.menu_grid}>
            <div>
              <ul className={h.nav_common}>{renderMenuItems(menuItems)}</ul>
              <ul className={h.mobile_nav}>
                {renderMenuItems(mobileMenuItems)}
              </ul>
            </div>
            <div className={h.nav_cards}>
              {roles.map(({ node }, i) => {
                const { ...data } = node.acf.roles
                const img = data.icon
                return renderCard(img, data.title, node.slug)
              })}
              {renderCard(contactImg, "Contact", "contact")}
            </div>
          </div>
        </Row>
      )}
    </header>
  )
}

export default Header
