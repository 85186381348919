import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import StartScreen from "../startscreen"
import SEO from "../seo"

import "normalize.css"
import "./layout.css"

const Layout = ({ children, path }) => {
  const data = useStaticQuery(graphql`
    query WpSiteData {
      allWordpressPage(filter: { template: { eq: "role.php" } }) {
        edges {
          node {
            wordpress_id
            slug
            acf {
              roles {
                title
                text
                label
                icon {
                  ...ImageDefault
                }
              }
            }
          }
        }
      }
      mainMenu: wordpressMenusMenusItems(slug: { eq: "main-menu" }) {
        ...Menu
      }
      mobileMenu: wordpressMenusMenusItems(slug: { eq: "mobile-menu" }) {
        ...Menu
      }
      wordpressSiteMetadata {
        url
        name
        description
      }
      homepage: wordpressPage(slug: { eq: "home" }) {
        acf {
          home {
            contact {
              contactinfo_title
              form_image {
                ...ImageDefault
              }
              address
              email
              phone
              title
              wordpress_fields {
                label
                type
                width
                required
              }
              btn_label
            }
            sections {
              heading
              image {
                ...ImageDefault
              }
              text
              button {
                btn_label
                btn_link
              }
            }
          }
        }
      }
      hf_info: wordpressPage(slug: { eq: "header-footer" }) {
        acf {
          hfs {
            startscreen {
              title
            }
            logos {
              logo_gw {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            contact_img {
              ...Image
            }
            social_icons {
              icoon {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 128) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              btn_link
            }
          }
        }
      }
    }
  `)

  const wpSiteUrl = data.wordpressSiteMetadata.url

  const cleanMenuItemSlugs = items =>
    items.map(i => {
      let obj = { ...i }
      obj.url = obj.url.replace(wpSiteUrl, "")

      // Check if item contains child items
      if (obj.child_items) {
        obj.child_items = obj.child_items.map(c => {
          let child_obj = { ...c }
          child_obj.url = child_obj.url.replace(wpSiteUrl, "")
          return child_obj
        })
      }

      return obj
    })

  // siteData contains data for header, footer and startscreen
  const siteData = data.hf_info.acf.hfs
  const homePageData = {
    contact: data.homepage.acf.home.contact,
    sections: data.homepage.acf.home.sections,
  }
  const roles = data.allWordpressPage.edges

  const headerData = {
    siteData,
    roles,
    menuItems: cleanMenuItemSlugs(data.mainMenu.items),
    mobileMenuItems: cleanMenuItemSlugs(data.mobileMenu.items),
  }

  const footerData = {
    social_icons: siteData.social_icons,
  }

  useEffect(() => {
    const scrollTo = e => {
      e.preventDefault()

      const target = document.querySelector(
        e.target.closest("a").getAttribute("href")
      )
      if (!target) return
      target.scrollIntoView({
        behavior: "smooth",
      })
    }

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", scrollTo)

      return () => {
        anchor.removeEventListener("click", scrollTo)
      }
    })
  })

  // See how header + startscreen are bound to index route, replacing index page (which is empty nevertheless)
  return (
    <>
      <Header {...headerData} />
      {path !== "/" ? (
        <main>{children}</main>
      ) : (
        <StartScreen
          siteData={{
            title: siteData.startscreen.title,
            homePageData,
          }}
          roles={roles}
        />
      )}
      <Footer {...footerData} />
    </>
  )
}

export default Layout
