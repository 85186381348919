import React from "react"
import { Link } from "gatsby"

import { formatBtnLabel } from "./utils/helpers"

import l from "./layout/layout.module.scss"

const Button = ({ data, color = "dark", extraClasses = [] }) => {
  const { btn_link: to, btn_label: label } = data
  if (to.charAt(0) === "/") {
    return (
      <Link to={to} className={[l.btn, l[color], ...extraClasses].join(" ")}>
        {formatBtnLabel(label)}
      </Link>
    )
  } else if (/^#|(^http)|(www)|(^tel)|(^mailto)/gi.test(to)) {
    return (
      <a
        href={to.trim()}
        className={[l.btn, l[color], ...extraClasses].join(" ")}
      >
        {formatBtnLabel(label)}
      </a>
    )
  } else {
    return null
  }
}

export default Button
