import React from "react"

const Heading = ({ type = "h2", color, children, className }) => {
  const Tag = type
  return (
    <Tag className={className} style={{ color }}>
      {children}
    </Tag>
  )
}

export default Heading
