import React from "react"

import l from "./layout/layout.module.scss"

const Row = ({
  children,
  bgColor = "transparent",
  customClass = "",
  id,
  fullScreen = false,
}) => {
  const formatId = (str = "") =>
    str && str.trim().toLowerCase().split(" ").join("-")

  return (
    <div
      id={formatId(id)}
      className={[
        l.row,
        l[bgColor],
        customClass,
        fullScreen && l.full_screen,
      ].join(" ")}
    >
      <div className={l.container}>{children}</div>
    </div>
  )
}

export default Row
